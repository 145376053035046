import { useRef, useEffect, RefObject } from 'react';

export default function ScrollPoint({
  active,
  scrollContainerRef,
  scrollContentRef,
  onBottomScrolled,
}: {
  active: boolean;
  scrollContentRef: RefObject<HTMLDivElement>;
  scrollContainerRef: RefObject<HTMLDivElement>;
  onBottomScrolled: () => void;
}) {
  const scrollPointRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const containerHeight = scrollContainerRef.current?.clientHeight;
    const contentHeight = scrollContentRef.current?.scrollHeight;

    if (!active || (contentHeight && containerHeight && contentHeight < containerHeight)) {
      return;
    }
    scrollContainerRef.current?.scrollTo({
      top: scrollPointRef.current?.offsetTop,
      behavior: 'smooth',
    });
  });

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    const scrollPoint = scrollPointRef.current;

    if (!scrollContainer || !scrollPoint) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          onBottomScrolled();
        }
      },
      {
        root: scrollContainer,
        threshold: 1.0,
      }
    );

    observer.observe(scrollPoint);

    return () => {
      observer.disconnect();
    };
  });

  return <div ref={scrollPointRef}></div>;
}
