import { Button, Icon, InputGroup, InputRightElement, Textarea, useToast } from '@chakra-ui/react';
import { FormEvent, useEffect, useRef, useState } from 'react';
import ResizeTextarea from 'react-textarea-autosize';

import BouncingLoader from '~/shared/components/ds/BouncingLoader.tsx';

import SendSvg from './assets/Send.svg?react';

const DEFAULT_HEIGHT = 39;
const DEFAULT_PLACE_HOLDER = 'Message Worknet...';

export default function UserInput({
  onSubmit,
  submitDisabled,
  isLoading,
}: {
  isBotTyping?: boolean;
  submitDisabled?: boolean;
  isLoading?: boolean;
  onSubmit: (text: string) => void;
}) {
  const [text, setText] = useState('');
  const [isMultiLine, setIsMultiLine] = useState(false);

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const disabled = submitDisabled || !text.trim();
  const toast = useToast();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (isLoading) {
      return;
    }
    if (disabled) {
      toast({
        title: 'Error',
        status: 'error',
        description: 'Please enter text before submitting',
        isClosable: true,
      });
      return;
    }
    onSubmit(text);
    setText('');
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  useEffect(() => {
    const inputField = inputRef.current;

    const isNonUserInput = (e: KeyboardEvent) => {
      // ignore shortcuts like Ctrl+C, Ctrl+V and function keys, arrows, Escape, Tab etc.
      return e.ctrlKey || e.metaKey || e.altKey || e.key.length !== 1;
    };

    const handleAutoFocusOnKeyDown = (e: KeyboardEvent) => {
      if (!inputField || document.activeElement === inputField || isNonUserInput(e)) {
        return;
      }

      inputField.focus();
    };

    document.addEventListener('keydown', handleAutoFocusOnKeyDown);
    return () => {
      document.removeEventListener('keydown', handleAutoFocusOnKeyDown);
    };
  }, []);

  return (
    <form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
      <InputGroup>
        <Textarea
          ref={inputRef}
          as={ResizeTextarea}
          onHeightChange={(h: number) => setIsMultiLine(h !== DEFAULT_HEIGHT)}
          rows={1}
          maxRows={6}
          _focus={{ outlineColor: 'transparent', boxShadow: '0px 0px 7px 0px #587AFF' }}
          onFocus={(e) => (e.target.placeholder = 'Typing')}
          onBlur={(e) => (e.target.placeholder = DEFAULT_PLACE_HOLDER)}
          placeholder={DEFAULT_PLACE_HOLDER}
          fontSize="chatText"
          borderRadius="5px"
          border="none"
          boxShadow="0 0 0 1px var(--chakra-colors-greyNatural)"
          _placeholder={{ color: 'greyDark' }}
          color="black"
          lineHeight="23px"
          value={text}
          resize="none"
          css={{ '&::-webkit-scrollbar': { width: '0px' } }}
          pr={isMultiLine ? '50px' : '40px'}
          pl="10px"
          onChange={(e) => setText(e.target?.value)}
        />
        <InputRightElement h="full" border="none">
          <Button
            h="full"
            fontSize="chatText"
            borderLeftRadius="0px"
            borderRightRadius="4px"
            type="submit"
            variant="link"
            disabled={disabled}
            pointerEvents={isLoading ? 'none' : 'auto'}
            backgroundColor={'main'}
            zIndex={5}
            {...(isMultiLine
              ? {
                  height: '27px',
                  minW: '30px',
                  mt: 'auto',
                  mr: '12px',
                  mb: '8px',
                  borderRadius: '3px',
                  overflow: 'hidden',
                }
              : {})}
          >
            {isLoading ? (
              <BouncingLoader variant="btn" />
            ) : (
              <Icon as={SendSvg} boxSize={isMultiLine ? '12px' : '16px'} ml="1px" fill="white" />
            )}
          </Button>
        </InputRightElement>
      </InputGroup>
    </form>
  );
}
